@import "../../../../../scss/helpers/variables";
@import "../../../../../scss/helpers/mixins";
.devices{
    hr{
        margin: 3px 0;
    }
    .tab-control{
        .inverter-item{
            background-color: #f3f5f7;
            @include border-radius(6px);
            padding: 15px;
            margin-top: 15px;
            @include box-shadow(0px 0px 3px #918888);
            img{
                max-width: 80%;
                &.off{
                    filter: grayscale(100%);
                    -webkit-filter: grayscale(100%);
                    -moz-filter: grayscale(100%);
                    -ms-filter: grayscale(100%);
                    -o-filter: grayscale(100%);
                }
            }
            .title{
                text-transform: uppercase;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: bold;
                font-size: 16px;
                border-bottom: 1px solid #DDD;
                padding-bottom: 5px;
            }
            .mccb{
                font-weight: bold;
                text-align: center;
                margin-top: 20px;
            }
            .action-on-off{
                background-color: $default;
                @include border-radius(6px);
                padding: 5px 0;
                text-align: center;
                text-transform: uppercase;
                color: #FFF;
                cursor: pointer;
                margin-top: 5px;
                &.off{
                    background-color: rgb(216, 0, 0);
                }
            }
            .iv-content{
                margin-top: 10px;
                span{
                    line-height: 30px;
                    background-color: #FFF;
                    display: inline-block;
                    width: 100%;
                    border: 1px solid #DDD;
                    text-align: center;
                    margin: 5px 0;
                    height: 30px;
                }
            }

            .calendar-onoff{
                text-align: right;
                margin-top: 10px;
                span{
                    display: inline-block;
                    cursor: pointer;
                    @include border-radius(6px);
                    padding: 5px 15px;
                    background: #f58634;
                    color: #FFF;
                }
            }
            
        }
    }
    .device-tab-scada{
        display: inline-block;
        width: 100%;
        text-align: left;
        ol{
            li{
                display: inline-block;
                height: 34px;
                line-height: 34px;
                margin-right: 15px;
                padding: 0 15px;
                @include border-radius(3px);
                text-transform: uppercase;
                cursor: pointer;
                background-color: #f5f5f5;
                &:hover{
                    background-color: $default;
                }
                &.active{
                    background-color: $default;
                    color: #FFF;
                }
            }
        }
    }
    .device-status{
        position: relative;
        padding: 5px;
        a{
            display: inline-block;
            position: relative;
            img{
                height: 16px;
            }
            .count-alert{
                position: relative;
                bottom: 0;
                display: inline-block;
                font-size: 10px;
                padding: 0 2px;
                margin-right: 5px;
            }
        }
    }
    .date{
        width: 200px;
        float: right;
    }
    .page-breadcrumb {
        ul{
            display: block;
            float: right;
        }
    }

    .data-view{
        overflow-x: auto;
        .main-device-scroll{
            min-width: 1720px;
        }
        
    }
    .main-chart-view{
        height: 100%;
        position: relative;
    }

    .modal-device{
        .box-info {
            .box-info-content{
                padding: 15px;
                border-top: 1px solid #DDD;
            }
        }
        .device-alarm-search{
            border: 1px solid #DDD;
            @include border-radius(4px);
            padding: 10px;
        }
        
    
        .device-tab{
            padding: 10px;
            ul{
                height: 34px;
                li{
                    display: inline-block;
                    line-height: 34px;
                    margin-right: 15px;
                    padding: 0 15px;
                    cursor: pointer;
                    @include border-radius(3px);
                    border: 1px solid #f3f5f7;
                    &:hover,
                    &.active{
                        color: #FFF;
                        background-color: $default;
                    }
                }
            }
        }
        .device-title{
            border: 1px solid #DDD;
            @include border-radius(3px);
            padding: 5px;
            background-color: #f3f5f7;
            .reload-device{
                background-color: $default;
                height: 40px;
                line-height: 40px;
                text-align: center;
                @include border-radius(3px);
                color: #fff;
                cursor: pointer;
            }
        }
    }
}

.modal-control-calendar{
    .modal-body{
        background-color: #FFF;
        .rbc-toolbar{
            margin-bottom: 30px;
        }
        .rbc-event-label{
            display: none;
        }
        .add-date{
            margin-bottom: 30px;
            border-bottom: 1px solid #DDD;
            padding-bottom: 30px;
            .validate-message{
                display: none;
            }
            .to{
                padding-top: 7px;
                display: inline-block;
            }
        }
    }
}
