@import "../../../../../../scss/helpers/variables";
@import "../../../../../../scss/helpers/mixins";
.modal-device{
    .modal-content{
        height: 100%;
        .box-info{
            min-height: 100%;
            margin-bottom: 0;
            position: relative;
        }
        .main-chart-view{
            .box-chart{
                position: relative;
                .main-param{
                    height: 100%;
                    width: 240px;
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    overflow: hidden;
                    .list-parameter{
                        border: 1px solid #DDD;
                        height: calc(100% - 25px);
                        overflow: hidden;
                        h2{
                            text-transform: uppercase;
                            font-size: 14px;
                            border-bottom: 1px solid #DDD;
                            padding-bottom: 5px;
                        }
                        .list-params{
                            height: calc(100% - 34px);
                            overflow-y: auto;
                        }
                    }
                }
                .chart-view{
                    position: absolute;
                    width: calc(100% - 270px);
                    top: 10px;
                    right: 10px;
                    height: 100%;
                }
            }
        }
    }
}
.device-charting{
    height: calc(100% - 54px);
    border-top: 1px solid #DDD;
    position: absolute;
    width: 100%;
    top: 54px;
    
    .main-chart-view{
        .box-chart{
            width: 100%;
            height: 100%;
            padding: 10px;
            .chart-content{
                height: calc(100% - 35px);
                padding-top: 15px;
                overflow: hidden;
            }
            .chart-filter{
                .time-filter{
                    float: right;
                    padding-right: 10px;
                    width: 50%;
                    .pad-line{
                        padding: 0 15px;
                    }
                    span{
                        &.icon{
                            padding: 5px;
                            position: relative;
                            top: 2px;
                            cursor: pointer;
                            &.disabled{
                                cursor: not-allowed;
                                color: #DDD;
                            }
                        }
                    }
                    label{
                        display: inline-block;
                        padding: 0 5px;
                        font-size: 0.9rem;
                    }
                }


                .filter-raw{
                    display: inline-block;
                    .main-raw{
                        display: inline-block;
                        border: 1px solid #DDD;
                        margin-right: 10px;
                        @include border-radius(3px);
                        span{
                            display: inline-block;
                            padding: 5px 15px;
                            border-right: 1px solid #DDD;
                            cursor: pointer;
                            border-bottom: 2px solid transparent;
                            &:last-of-type{
                                border-right: none;
                            }
                            &.active{
                                border-bottom-color:$default;
                                color: $default;
                            }
                            &.disabled{
                                cursor:not-allowed;
                                color: #c5c5c5;
                            }
                        }
                    }
                    
                }
                .filter{
                    text-align: right;
                    position: relative;
                    float: right;
                    width: 50%;
                    .view{
                        display: inline-block;
                        border: 1px solid rgba(0,0,0,.15);
                        padding: 5px 15px;
                        @include border-radius(3px);
                        text-align: left;
                        span{
                            position: relative;
                            top: 2px;
                            padding-left: 5px;
                            float: right;
                        }
                    }
                    .filter-dropdown{
                        position: absolute;
                        right: 0;
                        border: 1px solid rgba(0,0,0,.15);
                        @include border-radius(3px);
                        width: 140px;
                        display: none;
                        z-index: 9;
                        background-color: #FFF;
                        a{
                            display: inline-block;
                            width: 100%;
                            padding: 5px 15px;
                            text-align: left;
                            &:hover{
                                background-color: #E1F0FA;
                            }
                            &.active{
                                background-color: #E1F0FA;
                            }
                        }
                    }
                    &.on{
                        .filter-dropdown{
                            display: block;
                        }
                    }
                }
            }
        }

    }


}


