@import '../../../scss/helpers/variables';
@import '../../../scss/helpers/mixins';

.reset-password{
    #tsparticles{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        background: rgb(0,154,132);
        background: -moz-linear-gradient(90deg, rgba(0,154,132,1) 0%, rgba(99,247,226,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(0,154,132,1) 0%, rgba(99,247,226,1) 100%);
        background: linear-gradient(90deg, rgba(0,154,132,1) 0%, rgba(99,247,226,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#009a84",endColorstr="#63f7e2",GradientType=1);
    }
    .main-login{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        .content{
            max-width: 360px;
            margin: 0 auto;
            .main-form{
                background-color: #fff;
                @include border-radius(6px);
                margin-top: 10%;
                .logo{
                    text-align: center;
                    border-bottom: 1px solid #ddd;
                    padding: 10px 20px;
                    img{
                        width: 120px;
                    }
                }
    
                h1{
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight: 700;
                    color: #333333;
                    text-align: center;
                    padding: 20px 20px 0 20px;
                }
    
                .form{
                    padding: 20px;
                    .mb-3{
                        margin-bottom: 20px !important;
                    }

                    .btn-save{
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }
        }
        
        
    }
    
}