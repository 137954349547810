@font-face {
  font-family: "sola";
  src: url("../../assets/fonts/sola.eot");
  src: url("../../assets/fonts/sola.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/sola.woff") format("woff"),
    url("../../assets/fonts/sola.ttf") format("truetype"),
    url("../../assets/fonts/sola.svg#apptour") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "sola" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "sola" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-map-pin-streamline:before {
  content: "\61";
}
.icon-map-streamline-user:before {
  content: "\62";
}
.icon-angle-right:before {
  content: "\63";
}
.icon-angle-double-right:before {
  content: "\64";
}
.icon-angle-left:before {
  content: "\65";
}
.icon-angle-double-left:before {
  content: "\66";
}
.icon-bars:before {
  content: "\67";
}
.icon-list-unordered:before {
  content: "\68";
}
.icon-up-open-mini:before {
  content: "\69";
}
.icon-down-open-mini:before {
  content: "\6a";
}
.icon-down-open-big:before {
  content: "\6b";
}
.icon-down-arrow:before {
  content: "\6c";
}
.icon-up-arrow:before {
  content: "\6d";
}
.icon-up-open-big:before {
  content: "\6e";
}
.icon-filter:before {
  content: "\6f";
}
.icon-arrows-ccw:before {
  content: "\70";
}
.icon-man-people-streamline-user:before {
  content: "\72";
}
.icon-logout:before {
  content: "\73";
}
.icon-info-circle:before {
  content: "\74";
}
.icon-settings-streamline-1:before {
  content: "\75";
}
.icon-lock:before {
  content: "\76";
}
.icon-book:before {
  content: "\77";
}
.icon-017-teamwork-1:before {
  content: "\78";
}
.icon-collapse-prev:before {
  content: "\79";
}
.icon-collapse-next:before {
  content: "\7a";
}
.icon-graph-bar:before {
  content: "\41";
}
.icon-sort:before {
  content: "\42";
}
.icon-sort-amount-asc:before {
  content: "\43";
}
.icon-sort-amount-desc:before {
  content: "\44";
}
.icon-alert:before {
  content: "\71";
}
.icon-alert-1:before {
  content: "\45";
}
.icon-search:before {
  content: "\46";
}
.icon-key:before {
  content: "\47";
}
.icon-building-o:before {
  content: "\48";
}
.icon-power:before {
  content: "\49";
}
.icon-equalizer:before {
  content: "\4a";
}
.icon-flow-tree:before {
  content: "\4b";
}
.icon-add:before {
  content: "\4c";
}
.icon-plus:before {
  content: "\4d";
}
.icon-trash-o:before {
  content: "\4f";
}
.icon-cancel:before {
  content: "\50";
}
.icon-pencil-square-o:before {
  content: "\4e";
}
.icon-cancel-music:before {
  content: "\51";
}
.icon-calendar:before {
  content: "\52";
}
.icon-eye:before {
  content: "\53";
}
.icon-notepad:before {
  content: "\54";
}
.icon-print:before {
  content: "\55";
}
.icon-file-text-o:before {
  content: "\56";
}
.icon-download:before {
  content: "\57";
}
.icon-tachometer:before {
  content: "\58";
}
.icon-dashboard:before {
  content: "\59";
}
.icon-email-mail-streamline:before {
  content: "\5a";
}
.icon-gauge:before {
  content: "\30";
}
