@import "../../../../../../scss/helpers/variables";
@import "../../../../../../scss/helpers/mixins";
.chart-alarm{
    .chart-view-title{
        background-color: $default;
        h2{
            text-transform: uppercase;
            color: #FFF;
            font-size: 14px;
            padding: 7px 0;
            text-align: center;
            font-weight: bold;
        }
        p{
            color: #FFF;
            text-align: right;
            padding: 7px 5px;
        }
    }

    .box-item{
        border: 1px solid #DDD;
        margin-bottom: 15px;
        .title{
            border-bottom: 1px solid #DDD;
            padding: 5px 15px 5px 15px;
            background-color: #f3f5f7;
            h2{
                font-size: 14px;
                font-weight: bold;
                height: 34px;
                line-height: 34px;
            }
        }
        .box-item-chart{
            height: 380px;
            position: relative;
            ul{
                li{
                    border-bottom: 1px solid #DDD;
                    &:last-child{
                        border-bottom: none;
                    }
                    .item-alarm-sum{
                        padding: 15px 5px;
                        p{
                            font-size: 16px;
                            color: $orange;
                            font-weight: bold;
                        }
                    }
                }
            }
            .main-alarm{
                .main-header{
                    position: absolute;
                    width: 100%;
                    top: 0;
                    .header-row{
                        display: flex;
                        flex-wrap: wrap;

                        .header-col{
                            flex-grow: 1;
                            border-right: 1px solid #ddd;
                            border-bottom: 1px solid #ddd;
                            height: 40px;
                            line-height: 40px;
                            padding: 0 5px 0 5px;
                            position: relative;
                            &:last-child{
                                border-right: none;
                            }
                            p{
                                display: inline-block;
                                width: 100%;
                                font-weight: bold;
                            }
                        }
                    }
                }
                .main-body{
                    position: relative;
                    top: 40px;
                    height: 340px;
                    overflow-y: auto;
                    .body{
                        .body-row{
                            display: flex;
                            flex-wrap: wrap;
                            .body-col{
                                flex-grow: 1;
                                border-right: 1px solid #ddd;
                                border-bottom: 1px solid #ddd;
                                &:last-child{
                                    border-right: none;
                                }
                                p{
                                    padding: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    
}
