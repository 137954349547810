
body{
  font-family: Arial, sans-serif;
	font-size: 14px;
  	color: #222;
   background: #fff;
}

*{
  margin:0; 
  padding:0; 
  box-sizing:border-box;
}
ul,ol{
	list-style:none;
}
article,aside,details,figcaption,figure,
footer,header,hgroup,nav,section { 
    display:block;
}
.clearfix{
  @include clearfix;
}
p{
  margin: 0; padding: 0;
  line-height: 24px;
}
a{
  color: #222;
  cursor: pointer;
  @include transition(all 0.3s ease);
}
a, a:focus, a:hover, a:active{
  text-decoration: none;
}
a:focus, select:focus, input:focus, button:focus{
  outline: none;
}

/* reset webkit search input styles */
input[type=search] {
  -webkit-appearance: none;
  outline: none;
}
input[type="search"]::-webkit-search-decoration, 
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
.img-responsive, .thumbnail>img, .thumbnail a>img, .carousel-inner>.item>img, .carousel-inner>.item>a>img {
  display: block;
  width: 100%;
  height: auto;
}
img{
  max-width: 100%;
  height: auto;
}
ul, ol{
  list-style: none;
  margin: 0;
  padding: 0;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto{
  padding-right: 8px;
  padding-left: 8px;
}

.row {
  margin-right: -8px;
  margin-left: -8px;
}
.container-fluid,
.container{
  padding-right: 8px;
  padding-left: 8px;
}

input.form-control{
  height: 34px;
  font-size: 14px;
  padding: 5px;
  outline: none;
  &:visited,
  &:active,
  &:focus{
    outline: none;
    box-shadow: none;
  }
  &:hover{
    border-color: $default;
  }
}
.width5{
  width: 5%;
}
.width10{
  width: 10%;
}
.width15{
  width: 15%;
}
.width20{
  width: 20%;
}
.width25{
  width: 25%;
}
.width30{
  width: 30%;
}
.width35{
  width: 35%;
}
.width40{
  width: 40%;
}
.width45{
  width: 45%;
}
.width50{
  width: 50%;
}
.width55{
  width: 55%;
}
.width60{
  width: 60%;
}
.width65{
  width: 65%;
}
.width70{
  width: 70%;
}
.width75{
  width: 75%;
}
.width80{
  width: 80%;
}
.width85{
  width: 85%;
}
.width90{
  width: 90%;
}
.width95{
  width: 95%;
}
.width100{
  width: 100%;
}
.width85px{
  width: 85px;
  text-align: right;
  padding-right: 5px !important;
}

.input-group{
  .control-label{
    width: 100%;
  }
  .form-control{
    @include border-top-left-radius(3px);
    @include border-bottom-left-radius(3px);
    
  }
  .input-group-text{
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    padding: 0 5px;
    background-color: #f0f3f5;
  }
}

