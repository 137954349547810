@import "../../../../../../scss/helpers/variables";
@import "../../../../../../scss/helpers//mixins";
.view-map{
    height: 100%;
    position: relative;
    .data-view-map{
        width: 100%;
        height: calc(100% - 96px);
        margin-top: 10px;
        left: 0;
        background-color: #ddd;
        border: 1px solid #ddd;
        #post_view_map{
            width: 100%;
            height: 100%;
        }
    }
    .project-status{
        position: relative;
        padding: 5px;
        a{
            display: inline-block;
            position: relative;
            img{
                height: 16px;
            }
            .count-alert{
                position: relative;
                bottom: 0;
                background: rgb(255, 255, 0);
                display: inline-block;
                font-size: 8px;
                padding: 0 2px;
                border: 1px solid rgb(153, 153, 0);
                @include border-radius(3px);
                margin-right: 5px;
            }
        }
    }
    .info-content{
        max-width: 300px;
        padding-top: 10px;
        p{
            strong{
                font-weight: bold;
            }
        }
    }
}
