@import "../../../../../scss/helpers/variables";
@import "../../../../../scss/helpers/mixins";
.employee{
    .data-view {
        .main-body {
            .body {
                .body-row{
                    .body-col {
                        p{
                            span{
                                background-color: #e2e2e2;
                                @include border-radius(3px);
                                color: #222;
                                padding: 2px 10px;
                                font-size: 12px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    .group-password {
        position: relative;
        .form-control{
            padding-right: 115px;
        }
        .generate-pass{
            position: absolute;
            height: 34px;
            right: 0;
            top: 21px;
            line-height: 34px;
            padding: 0 10px;
            background: $default;
            @include border-top-right-radius(4px);
            @include border-bottom-right-radius(4px);
            color: #FFF;
            cursor: pointer;
        }
    }
}
