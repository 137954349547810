@import '../../../../scss/helpers/variables';
@import '../../../../scss/helpers/mixins';

.account{
    .add{
        display: inline-block;
        @include border-radius(3px);
        background-color: $default;
        color: #FFF;
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        cursor: pointer;
        &:hover{
            background-color: #02c1a6;
        }
        span{
            position: relative;
            top: 2px;
            padding-right: 5px;
        }
    }
    .form-account{
        padding-top: 15px;
        .alert{
            margin-top: 15px;
        }
    }
    .group-password {
        position: relative;
        .form-control{
            padding-right: 115px;
        }
        .generate-pass{
            position: absolute;
            height: 34px;
            right: 0;
            top: 21px;
            line-height: 34px;
            padding: 0 10px;
            background: $default;
            @include border-top-right-radius(4px);
            @include border-bottom-right-radius(4px);
            color: #FFF;
            cursor: pointer;
        }
    }
}