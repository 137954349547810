@import "../../helpers/variables";
@import "../../helpers/mixins";

/* Checkboxes
   ========================================================================== */
/* CUSTOM CSS CHECKBOXES */
  
  /* Remove default checkbox */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    opacity: 0;
  }
  
  [type="checkbox"] {
    /* checkbox aspect */
  }
  
  [type="checkbox"] + label {
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
  }
  
  [type="checkbox"] + label:before,
  [type="checkbox"]:not(.filled-in) + label:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    z-index: 0;
    border: 1px solid #DDD;
    border-radius: 2px;
  }
  
//   [type="checkbox"]:not(.filled-in) + label:after {
//     border: 0;
//     -webkit-transform: scale(0);
//             transform: scale(0);
//   }
  
  [type="checkbox"]:not(:checked):disabled + label:before {
    border: none;
    background-color: #DDD;
  }
  
  [type="checkbox"].tabbed:focus + label:after {
    -webkit-transform: scale(1);
            transform: scale(1);
    border: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  [type="checkbox"]:checked + label:before {
    top: 10px;
    left: 10px;
    width: 8px;
    height: 16px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid $default;
    border-bottom: 2px solid $default;
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  
  [type="checkbox"]:checked:disabled + label:before {
    border-right: 2px solid rgba(0, 0, 0, 0.26);
    border-bottom: 2px solid rgba(0, 0, 0, 0.26);
  }
  
  /* Indeterminate checkbox */
  [type="checkbox"]:indeterminate + label:before {
    top: -11px;
    left: -12px;
    width: 10px;
    height: 22px;
    border-top: none;
    border-left: none;
    border-right: 2px solid #22a5e9;
    border-bottom: none;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  
  [type="checkbox"]:indeterminate:disabled + label:before {
    border-right: 2px solid rgba(0, 0, 0, 0.26);
    background-color: transparent;
  }
  
  [type="checkbox"].filled-in + label:after {
    border-radius: 2px;
  }
  
  [type="checkbox"].filled-in + label:before,
  [type="checkbox"].filled-in + label:after {
    content: '';
    left: 0;
    position: absolute;
    /* .1s delay is for check animation */
    transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
    z-index: 1;
  }
  
  [type="checkbox"].filled-in:not(:checked) + label:before {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 10px;
    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    transform-origin: 100% 100%;
  }
  
  [type="checkbox"].filled-in:not(:checked) + label:after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #5a5a5a;
    top: 0px;
    z-index: 0;
  }
  
  [type="checkbox"].filled-in:checked + label:before {
    top: 0;
    left: 1px;
    width: 8px;
    height: 13px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  
  [type="checkbox"].filled-in:checked + label:after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $default;
    background-color: $default;
    z-index: 0;
  }
  
  [type="checkbox"].filled-in.tabbed:focus + label:after {
    border-radius: 2px;
    border-color: #5a5a5a;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  [type="checkbox"].filled-in.tabbed:checked:focus + label:after {
    border-radius: 2px;
    background-color: #22a5e9;
    border-color: #22a5e9;
  }
  
  [type="checkbox"].filled-in:disabled:not(:checked) + label:before {
    background-color: transparent;
    border: 2px solid transparent;
  }
  
  [type="checkbox"].filled-in:disabled:not(:checked) + label:after {
    border-color: transparent;
    background-color: #BDBDBD;
  }
  
  [type="checkbox"].filled-in:disabled:checked + label:before {
    background-color: transparent;
  }
  
  [type="checkbox"].filled-in:disabled:checked + label:after {
    background-color: #BDBDBD;
    border-color: #BDBDBD;
  }