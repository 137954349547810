@import "../../helpers/variables";
@import "../../helpers/mixins";
/* Switch
   ========================================================================== */
   .switch,
   .switch * {
     -webkit-user-select: none;
     -moz-user-select: none;
     -khtml-user-select: none;
     -ms-user-select: none;
   }
   
   .switch label {
     cursor: pointer;
   }
   
   .switch label input[type=checkbox] {
     opacity: 0;
     width: 0;
     height: 0;
   }
   
   .switch label input[type=checkbox]:checked + .lever {
     background-color: $default;
   }
   
   .switch label input[type=checkbox]:checked + .lever:after {
     background-color: $default;
     left: 24px;
   }
   
   .switch label .lever {
     content: "";
     display: inline-block;
     position: relative;
     width: 40px;
     height: 15px;
     background-color: #818181;
     border-radius: 15px;
     margin-right: 10px;
     transition: background 0.3s ease;
     vertical-align: middle;
     margin: 0 16px;
   }
   
   .switch label .lever:after {
     content: "";
     position: absolute;
     display: inline-block;
     width: 21px;
     height: 21px;
     background-color: #F1F1F1;
     border-radius: 21px;
     box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
     left: -5px;
     top: -3px;
     transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease;
   }
   
   input[type=checkbox]:checked:not(:disabled) ~ .lever:active::after,
   input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::after {
     box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1);
   }
   
   input[type=checkbox]:not(:disabled) ~ .lever:active:after,
   input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::after {
     box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08);
   }
   
   .switch input[type=checkbox][disabled] + .lever {
     cursor: default;
   }
   
   .switch label input[type=checkbox][disabled] + .lever:after,
   .switch label input[type=checkbox][disabled]:checked + .lever:after {
     background-color: #BDBDBD;
   }
   
   input.chk-default:not(:checked),
   input.chk-default:checked {
     position: unset !important;
     opacity: 1 !important;
    
   }

.checkmark {
  .switch {
      label {
          margin-bottom: 0;

          input[type=checkbox] {
              display: none;
          }

          .lever {
              width: 24px;
              height: 12px;
              margin: 0;

              &::after {
                  width: 8px;
                  height: 8px;
                  left: 2px;
                  top: 2px;
                  @include box-shadow(none);
                  background: #FFF;
              }
          }

          input[type=checkbox]:checked+.lever.switch-col-darkblue:after {
              background: #fff;
              left: 12px;
          }

          input[type=checkbox]:checked+.lever.switch-col-darkblue {
              background: $default;
          }
      }
  }
}